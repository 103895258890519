<template>
  <div class="shop">
    <!-- 搜索 -->
    <van-sticky :offset-top="0">
      <van-search v-model="search"
        placeholder="请输入搜索关键词"
        @search="onSearch"
        shape="round"
        left-icon="https://lyxnyapi.dexian.ren//upload/images/2022/7/714ba889-821.png"></van-search>
      <div class="searchOn"
        @click="searchOn">搜索</div>
    </van-sticky>
    <!-- 轮播图 -->
    <div class="swipeBox">
      <van-swipe class="my-swipe"
        :autoplay="3000"
        indicator-color="white">
        <van-swipe-item><img src="../../assets/商家轮播.png"
            style="width: 100%;height: 100%;"></van-swipe-item>
        <!-- <van-swipe-item><img src="../../assets/qiye.png"
            style="width: 100%;height: 100%;"></van-swipe-item> -->
      </van-swipe>
    </div>
    <!-- swiper切换栏 -->
    <!-- <div style="border-radius: 5px;">
      <div style="border-radius: 5px;">
        <van-swipe class="my-swipe"
          indicator-color="white"
          loop>
          <van-swipe-item>
            <van-grid class="wktype"
              :border="false"
              :square="true"
              column-num="5">
              <template>
                <van-grid-item icon="photo-o"
                  @click="gotoUrl('/shop/PromotionList?id=01')">
                  <van-image class="iconimg"
                    src="https://lyxnyapi.dexian.ren//upload/images/2022/7/91b592c7-0c4.png"></van-image>
                  <span class="wktext">商超百货</span>
                </van-grid-item>
              </template>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=02')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/26e26f0e-33e.png"></van-image>
                <span class="wktext">滋补保健</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=03')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/fd46f477-eff.png"></van-image>
                <span class="wktext">粮油调味</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=04')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/f2e4af73-093.png"></van-image>
                <span class="wktext">医疗健康</span>
              </van-grid-item> -->
    <!-- <template>
                <van-grid-item icon="photo-o"
                  @click="gotoUrl('/shop/PromotionList?id=05')">
                  <van-image class="iconimg"
                    src="https://lyxnyapi.dexian.ren//upload/images/2022/7/a61201d8-dc7.png"></van-image>
                  <span class="wktext">母婴幼儿</span>
                </van-grid-item>
              </template>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=06')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/7c15d48f-7aa.png"></van-image>
                <span class="wktext">电器数码</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=07')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/71f7de50-bc2.png"></van-image>
                <span class="wktext">美妆/服饰</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=08')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/a31a7675-343.png"></van-image>
                <span class="wktext">日用清洁</span>
              </van-grid-item>
              <template>
                <van-grid-item icon="photo-o"
                  @click="gotoUrl('/shop/PromotionList?id=09')">
                  <van-image class="iconimg"
                    src="https://lyxnyapi.dexian.ren//upload/images/2022/7/bc192941-0f8.png"></van-image>
                  <span class="wktext">宠物生活</span>
                </van-grid-item>
              </template> -->
    <!-- <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionType')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/39457095-1ec.png"></van-image>
                <span class="wktext">更多</span>
              </van-grid-item>
            </van-grid>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div> -->
    <!-- 商品图 -->
    <!-- <div style="margin: 10px auto;width: 95%;">
      <img src="../../assets/shop/bg1.png"
        style="width: 100%;">
    </div> -->
    <!-- 商品列表 -->
    <div class="title">促销活动</div>
    <!-- <div class="information"
      style="margin-bottom: 55px;">
      <div class="center"
        style="padding:0;"
        v-for="item in list"
        :key="item.index">
        <div @click="gotoUrl('/shop/PromotionXq/' + item.PromotionId)">
          <van-card style="padding: 10px 0px;background-color: white;border-radius: 8px;">
            <template #thumb>
              <div class="van-card__thumb">
                <img v-if="item.Poster == ''"
                  style="width:100%;height:100%;"
                  src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
                <img v-else
                  style="width:100%;height:100%;"
                  :src="item.PosterUrl.split(',')[0]" />
              </div>

            </template>
            <template #title>
              <div class="centerTitle van-ellipsis--l1"
                style="width: 75%;">{{ item.Title }}</div>
              <div class="county">{{item.ShopName}}</div>
            </template>

            <template #tags>
              <van-tag class="tagClass"
                plain
                type="danger"
                color="#ffe1e1"
                text-color="#FE5B47">{{item.EnrolNum}}/{{item.Quota}} 名</van-tag>
              <div style="width: 75%;font-size:13px;"
                class="van-multi-ellipsis--l2">{{item.Address}}</div>
            </template>
            <template #num>
              <div style="padding-right:5px;">
                &nbsp;{{item.IssTime}}</div>
            </template>
          </van-card>
        </div>
      </div>
    </div> -->
    <van-list v-model="loading"
      :finished="finished"
      offset="50"
      @load="onLoad">
      <div class="lists">
        <div class="list"
          @click="gotoUrl('/shop/PromotionXq/' + item.PromotionId)"
          v-for="item in list"
          :key="item.ShopId">
          <div class="imgs"><img :src="item.PosterUrl"></div>
          <div class="listTitle">{{item.Title}}</div>
          <div class="price">
            <span style="font-weight: 700;font-size: 12px;">{{item.ShopName}}</span>
            <span class="shopType"
              v-if="item.ShopTypeName">{{item.ShopTypeName}}</span>
          </div>
          <div class="shopBox">
            <span>{{item.IssTime}}</span>
          </div>
        </div>
      </div>
    </van-list>
    <!-- tabbar栏 -->
    <van-tabbar v-model="tabbaron"
      active-color="#3a95cc"
      inactive-color="#ccc">
      <van-tabbar-item replace
        to="/shop"
        icon="shop">商城首页</van-tabbar-item>
      <van-tabbar-item replace
        to="/PromotionBm"
        icon="user-o">报名信息</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { ref } from 'vue';
import { WxGetPromotionPage } from "@/api/shop";
import { GetCodeEntrysList } from "@/api/ResumeJS";
export default {
  setup () {
    const tabbaron = ref(0);
    return { tabbaron };
  },
  data () {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      typeList: [],
      tabbaron: 0,
    };
  },
  methods: {
    // 搜索
    searchOn () {
      this.getList(this.search);
    },
    // 跳转
    gotoUrl (path) {
      this.$router.push(path);
    },
    // 点击进入商户详情
    detailClick (id) {
      var url = '/shop/detail?Id=' + id
      this.$router.push(url);
    },
    // 获取字典

    // 获取分页列表
    getList (row) {
      this.listfrom.title = row;
      WxGetPromotionPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].Poster == "") {
                  this.list[i].PosterUrl =
                    "https://lyxnyapi.dexian.ren//upload/images/2022/7/b776c8ca-b1f.png";
                }
              }
            }
          }
        })
    },
    // 下拉刷新
    onLoad () {
      this.listfrom.limit = this.listfrom.limit + 30;
      this.getList(undefined);
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList(undefined);
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList(undefined);
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    // 字典
    getTypeList () {
      GetCodeEntrysList({ kind: "310" }).then((res) => {
        this.typeList = res.data.data;
      });
    }
  },
  mounted () {
    this.getList(undefined);
    this.getTypeList();
  },
};
</script>
<style scoped>
.shop {
  background: #f7f7f7;
  min-height: 90vh;
}

.van-card__thumb {
  margin-right: 22px;
  margin-left: 5px;
}

.shopTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
}

.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}

.shop .center {
  width: 95%;
  border-radius: 8px;
  margin: 10px auto;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
  /* background: rgb(246, 243, 243); */
}

.centertime {
  font-size: 14px !important;
}

.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}

/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}

/deep/.van-search__content--round {
  border: 1px solid #fdd64f;
}

/deep/.van-search {
  background: #f4f4f4;
}

.wktype {
  padding: 0 5px 5px;
}

.wktype .iconimg {
  width: 65%;
}

.wktype .wktext {
  margin-top: 8px;
  font-size: 0.75rem;
}

.lists {
  column-count: 2;
  column-gap: 0px;
  width: 100%;
  margin: 10px auto;
  padding-bottom: 90px;
}

.list {
  margin: 10px 8px;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  white-space: normal;
  word-break: break-all;
  -webkit-column-break-inside: avoid;
}

.list .listTitle {
  font-size: 14px;
  font-weight: 700;
  margin: 0px 5px;
  color: #69afe3;
}

.list .imgs {
  height: 100%;
  overflow: auto;
}

.list .imgs img {
  max-height: 300px;
  width: 100%;
  min-height: 135px;
  height: 100%;
  overflow: auto;
}

.list .price {
  color: #222426;
  margin: 3px 5px;
}
.list .shopType {
  background: #d9841a;
  color: #fff;
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 12px;
  margin-left: 6px;
}
.title {
  position: relative;
  font-weight: 700;
  margin: 10px 20px;
  font-size: 16px;
}

.title::before {
  content: '';
  position: absolute;
  top: 0px;
  left: -10px;
  width: 5px;
  height: 21px;
  background: #fdd64f;
  border-radius: 5px;
}

.searchOn {
  position: absolute;
  top: 13px;
  right: 15px;
  background: linear-gradient(to bottom right, #ffe03e, #ffbe00);
  border-radius: 15px;
  padding: 6px 18px;
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 700;
}

.shopBox {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 3px 0%;
  padding: 0px 0px 5px;
}

.shopBox span {
  color: #808080;
  font-size: 12px;
  margin-left: 5px;
}

.swipeBox {
  width: 95%;
  margin: 5px 2.5%;
  height: 150px;
}

.swipeBox .van-swipe {
  height: 150px;
  border-radius: 8px;
}

/deep/.wktype .van-grid-item__content {
  background: #f7f7f7;
}
.tagClass {
  margin: 5px 0;
}
</style>